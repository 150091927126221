import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import deviceMarketplaceStoreModule from '../deviceMarketplaceStoreModule'

export default function useDevicesMarketplaceList() {
  const DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME = 'app-devices-marketplace'

  // Register module
  if (!store.hasModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME, deviceMarketplaceStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const refDeviceMarketplaceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions' },
    { key: 'name', sortable: true },
    { key: 'maxQuantity' },
    { key: 'status' },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const nameFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceMarketplaceListTable.value ? refDeviceMarketplaceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refDeviceMarketplaceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, nameFilter], () => {
    refetchData()
  })

  const fetchMarketplaceProducts = (ctx, callback) => {
    store
      .dispatch('app-devices-marketplace/fetchMarketplaceProducts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        name: nameFilter.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Marketplace Products list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMarketplaceProducts,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDeviceMarketplaceListTable,

    refetchData,

    // Extra Filters
    nameFilter,
  }
}
