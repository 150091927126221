<template>

  <div>

    <!-- Filters -->
    <devices-marketplace-list-filters :name-filter="nameFilter" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="$ability.can('C', 'Devices Marketplace')"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-devices-marketplace-create' }"
              >
                <span class="text-nowrap">Add Device</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refDeviceMarketplaceListTable"
        class="position-relative"
        :items="fetchMarketplaceProducts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="$ability.can('E', 'Devices Marketplace')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('E', 'Devices Marketplace')"
              :to="{ name: 'general-settings-devices-marketplace-edit', params: { id: data.item.productId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>

          <span
            v-else
            class="text-muted"
          >
            N/A
          </span>
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="35"
                :src="data.item.thumbnailPhotoUrl"
                :icon="'laptop'"
                variant="light-primary"
              />
            </template>
            <span class="text-capitalize">{{ data.item.name }}</span>
            <br>
            <small class="text-muted">{{ data.item.description }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTable,
  BIconLaptop,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useDevicesMarketplaceList from './useDevicesMarketplaceList'
import DevicesMarketplaceListFilters from './DevicesMarketplaceListFilters.vue'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,

    BIconLaptop,

    vSelect,

    DevicesMarketplaceListFilters,
  },
  setup() {
    const {
      fetchMarketplaceProducts,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDeviceMarketplaceListTable,
      refetchData,

      // Extra Filters
      nameFilter,
    } = useDevicesMarketplaceList()

    return {
      fetchMarketplaceProducts,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDeviceMarketplaceListTable,
      refetchData,

      // Extra Filters
      nameFilter,

      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
