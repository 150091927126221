import {
  fetchMarketplaceProductsAPI,
  fetchMarketplaceProductAPI,
  createMarketplaceProductAPI,
  updateMarketplaceProductAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {
    marketplaceProducts: [],
  },
  getters: {
    marketplaceProductOptions(state) {
      if (state.marketplaceProducts.length <= 0) {
        return []
      }
      return state.marketplaceProducts.map(obj => ({
        label: obj.name,
        value: obj.productId,
        image: obj.thumbnailPhotoUrl,
        description: obj.description,
      }))
    },
  },
  mutations: {
    SET_MARKETPLACE_PRODUCTS_OPTIONS(state, data) {
      state.marketplaceProducts = data
    },
  },
  actions: {
    async fetchMarketplaceProducts(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchMarketplaceProductsAPI, { params: queryParams })
        ctx.commit('SET_MARKETPLACE_PRODUCTS_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchMarketplaceProduct(ctx, id) {
      try {
        const response = await this.$axios.get(`${fetchMarketplaceProductAPI}/${id}`)
        return response
      } catch (error) {
        return error
      }
    },
    async createMarketplaceProduct(ctx, params) {
      try {
        const response = await this.$axios.post(createMarketplaceProductAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
    async updateMarketplaceProduct(ctx, params) {
      try {
        const response = await this.$axios.post(updateMarketplaceProductAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
